import React, {useState, useEffect, useRef} from 'react';
import { useWhisper } from '@chengsokdara/use-whisper';
import axios from "axios";


const App = () => {
    const {
        recording,
        transcript,
        startRecording,
        stopRecording,
    } = useWhisper({
        apiKey: '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', // YOUR_OPEN_AI_TOKEN
        nonStop: true, // keep recording as long as the user is speaking
        stopTimeout: 2500, // auto stop after 1 second
    });

    let [talkingStatus, setTalkingStatus] = useState("stop");
    const [selectedOption, setSelectedOption] = useState('');

    let [error, setError] = useState(false);

    //0 - Nothing
    //1 - Init, character's intro
    //2 - Ready for user's push
    //3 - Listening to User
    //4 - Thinking
    const [conversation_status, setConversation_status] = useState(0);

    let gpt_result = '';

    let loading = null;

    const [lastMessages, setLastMessages] = useState([]);


    const sendRequestToOpenAI = async (message) => {
        loading = 'Waiting for an answer';
        const openaiApiToken = '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';
        const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${openaiApiToken}`,
        };

        let character = voices.find(v => v.voice_id == selectedOption)?.name

        const data = {
            'model': 'gpt-4',
            'messages': [
                {'role': 'system', 'content': 'You are '+character+'. Dont say that you are AI language model. Imagine you are '+character+' and talk to me as if you were '+character},
                ...message
            ],
        };

        const article = { title: 'Axios POST Request Example' };
        const response = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            data,
            {headers}
        ).then(async data => {
            console.log(data)
            setTalkingStatus("responding")
            await textToSpeech(data.data.choices[0].message.content)
        }).catch(err => {
            setError('Error sending request to OpenAI. Please try again...')
            console.error('Error sending request to OpenAI:', err);
            setConversation_status(0)
        });
    };


    const audioQueue = []
    let isPlaying = false;

    const voices = [
        {
            "voice_id": "26Fiy2ujFazHQ3n2cfdt",
            "name": "Peppa Pig"
        },
        {
            "voice_id": "7MK5Y1Vy1LqC0NjBGYOs",
            "name": "Mickey Mouse"
        },
        {
            "voice_id": "DsfHxiAPLxzGezljARLM",
            "name": "Masianya"
        },
        {
            "voice_id": "E5wl0kvmul2Fbl6wegK3",
            "name": "Elza from Frozen"
        },
        {
            "voice_id": "Gpmyk3BqYbQoLpcjqbUC",
            "name": "Ollaf from Frozen"
        },
        {
            "voice_id": "Ipdl4qYtAxNYwvg1pkfM",
            "name": "Anna from Frozen"
        },
        {
            "voice_id": "UAAyX4LqVt3159Jut2wP",
            "name": "Ledybug"
        },
        {
            "voice_id": "UAwhxZqJhP50vO46tWJ6",
            "name": "Darth Vader"
        },
        {
            "voice_id": "UhS7nVs4qtsGa7KYdFMO",
            "name": "Donald Duck"
        },
        {
            "voice_id": "qb45xgoOj4qDqZKvcRzd",
            "name": "Yoda"
        }
    ];

    const textToSpeech = async (text) => {
        const voiceId = selectedOption ? selectedOption : 'pNInz6obpgDQGcFmaJgB';
        const xiApiKey = 'c165e90a1f2294b15a301391048391b5';
        //const xiApiKey = 'c7c95f73e5b4690218abab62c20fe8bf';
        const url = `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream`;

        const headers = {
            'Accept': 'audio/mpeg',
            'Content-Type': 'application/json',
            'xi-api-key': xiApiKey,
        };

        const data = {
            'text': text.trim(),
            'model_id': 'eleven_monolingual_v1',
            'voice_settings': {
                'stability': 1,
                'similarity_boost': 1,
            },
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
        });

        if (response.ok && !recordingStarted) {
            const audioBlob = await response.blob();
            const audioUrl = URL.createObjectURL(audioBlob);
            if(audioQueue.length >= 1 && selectedOption == 'UAwhxZqJhP50vO46tWJ6'){
                audioQueue.push('/samples/dv.mp3')
                playAudio();
            }
            audioQueue.push(audioUrl);
            playAudio();
        } else {
            setError('Error fetching audio stream. Please try again...')
            setConversation_status(0)
            console.error('Error fetching audio stream:', response.statusText);

        }
    }

    let recordingStarted = false

    function releaseToStop(){
        stopRecording();
    }

    function pushToTalk(){
        setError(false)
        gpt_result = ''
        audioQueue.splice(0,audioQueue.length)
        startRecording()
        recordingStarted = true
    }

    function startTalking(){
        if(recording){
            stopRecording();
        }
        else{
            gpt_result = ''
            startRecording()
            recordingStarted = true
        }
    }

    function callToCharacter(){
        setError(false)
        setConversation_status(1)
        if(selectedOption == 'UAwhxZqJhP50vO46tWJ6')
        {
            audioQueue.push('/samples/dv.mp3')
            playAudio();
        }
        let character = voices.find(v => v.voice_id == selectedOption)?.name
        let message = {'role': 'user', 'content': `Hey, ${character}`};
        setLastMessages([...lastMessages, message])
        //textToSpeech(`Hey! It's ${character}. Let's have a conversation!`)
    }

    const AudioContext = window.AudioContext || window.webkitAudioContext;


    async function playAudioBuffer(buffer, audioContext) {
        const source = audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.destination);
        source.start(0);

        return new Promise((resolve) => {
            source.onended = () => {
                resolve();
            };
        });
    }

    async function fetchAndDecodeAudio(url, audioContext) {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        return audioBuffer;
    }

    async function playAudio() {
        if (audioQueue.length > 0 && !isPlaying && !recordingStarted) {
            const audioContext = new AudioContext();
            isPlaying = true;
            setTalkingStatus('responding');
            const audioBuffer = await fetchAndDecodeAudio(audioQueue[0], audioContext);
            await playAudioBuffer(audioBuffer, audioContext);
            URL.revokeObjectURL(audioQueue.shift());
            isPlaying = false;

            if (audioQueue.length === 0) {
                let message = { 'role': 'system', 'content': gpt_result };
                setLastMessages([...lastMessages, message]);

                setTalkingStatus('end');
                if(conversation_status == 1){
                    setConversation_status(2)
                }
            } else {
                playAudio();
            }
        }
    }

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };


    useEffect(() => {
        let lastMessage = lastMessages.length > 0 ? lastMessages[lastMessages.length-1] : {};
        if(lastMessages.length > 0 && lastMessage.role !== 'system')
            sendRequestToOpenAI(lastMessages);
    }, [lastMessages])

    useEffect(() => {
        let character = voices.find(v => v.voice_id == selectedOption)?.name
        if (transcript.text) {
            let message = {'role': 'user', 'content': character+', '+transcript.text};
            setLastMessages([...lastMessages, message])
        }
    }, [transcript])

    useEffect (() => {

    }, audioQueue)

    useEffect(() => {
        if (recording) {
            setTalkingStatus("listening")
        }
        else{
            setTalkingStatus("stop")
        }
    }, [recording]);

    return (
        <div>
            <div className="select">
                <select onChangeCapture={() => {setConversation_status(0); setError(false)}} value={selectedOption} onChange={handleChange}>
                    <option value="">Select voice</option>
                    {voices.map((item, index) => (
                        <option key={index} value={item.voice_id}>{item.name}</option>
                    ))}
                </select>
            </div>

            <div className={`start`}>
                {conversation_status == 0 && <button onClick={() => {callToCharacter(); setError(false)}}>{recording ? 'Stop' : 'Start'}</button> }
                {conversation_status > 1 &&
                    <button
                            className={`${recording ? 'rec' : ''}`}
                            onPointerDown={() => pushToTalk()}
                            onPointerUp={() => releaseToStop()}
                    >
                        {recording ? 'Release to send' : 'Push to talk'}
                    </button> }
                {conversation_status == 1 && <div className={'status-container'}>
                    Thinking...
                </div>}
                {
                   talkingStatus == 'responding' && <div className={'status-container-text'}>I am answering...</div>
                }
                {
                    error && <div className={'status-container-text'}>{error}</div>
                }
            </div>
        </div>
    );
};

export default App;